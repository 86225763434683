<template>
  <div>
    <form
      name="missForm"
      class="p-col-12"
      @submit.prevent="saveNewItem"

    >
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
          <span class="p-float-label">
            <InputText
              type="text"
              maxlength="60"
              v-validate="'required'"
              v-model="newItem.description"
              v-bind:class="[
                { 'p-invalid': errors.has('description') },
                'form-control',
              ]"
              :id="'description'"
              :name="'description'"
            />
            <label for="pats">Descrição</label>
          </span>
          <small v-if="errors.has('description')" class="p-error" role="alert">
            Descrição é obrigatório
          </small>
        </div>

        <div class="p-field p-col-12 p-md-12 p-mt-2">
          <span class="p-float-label">
            <InputNumber
              v-model="newItem.installedQuantity"
              mode="decimal"
              :maxFractionDigits="3"
              v-validate="'required'"
              v-bind:class="[
                { 'p-invalid': errors.has('installedQuantity') },
                'form-control',
              ]"
              :id="'installedQuantity'"
              :name="'installedQuantity'"
            />
            <label for="pats">Quantidade Instalada</label>
          </span>
          <small
            v-if="errors.has('installedQuantity')"
            class="p-error"
            role="alert"
          >
            Quantidade Instalada é obrigatório
          </small>
        </div>

        <div class="p-field p-col-12 p-md-12 p-mt-2">
          <span class="p-float-label">
            <InputText
              type="text"
              maxlength="40"
              v-model="newItem.observations"
              v-bind:class="['form-control']"
              :id="'observations'"
              :name="'observations'"
            />
            <label for="pats">Observações</label>
          </span>
        </div>

      </div>
    </form>
    <divider/>
      <div class="p-field p-col-12 p-md-12 p-mt-2 p-text-right">
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-text p-button-success"
          @click="saveNewItem"
        />
        <Button
          :label="'Cancelar'"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="cancelNewItem"
        />
      </div>
  </div>
</template>

<script>
import dossierLinesService from "../services/dossierLines.service";

export default {
  name: "TaskInstallationSheetForm",
  props: ["installationId"],
  data() {
    return {
      loading: false,
      newItem: {
        description: null,
        observations: '',
        installedQuantity: null,
      },
    };
  },
  methods: {
    saveNewItem() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        let bodyParms = {
          'internalDossierid': this.installationId,
          'lineDescription': this.newItem.description,
          'lineObservations': this.newItem.observations,
          'lineInstalledQuantity': this.newItem.installedQuantity,
        }
        dossierLinesService.addLine(bodyParms).then(response => {
          this.newItem.description = null;
          this.newItem.observations = '';
          this.newItem.installedQuantity = null;
           this.$emit("submitNewItem", response);
        })
      });
    },
    cancelNewItem() {
      this.newItem.description = null;
      this.newItem.observations = '';
      this.newItem.installedQuantity = null;
      this.$emit("cancelNewItem", this.newItem);
    }
  },
};
</script>
