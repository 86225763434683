<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="formNewItem"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Adicionar Material à Instalação'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <TaskInstallationSheetForm
        v-on:submitNewItem="submitNewItem"
        v-on:cancelNewItem="canceledNewItem"
        :installationId="sheetReference"
      />
    </Dialog>
    <h1>Folha de Instalação</h1>
    <div
      class="p-field p-col-12 p-md-12"
      v-for="sheet in installationSheets"
      :key="sheet.reference"
    >
      <Divider />
      <div class="p-fluid p-formgrid p-grid p-col-12 p-md-12">
        <div class="p-field p-col-12 p-md-6">
          <div v-if="sheet.technician" class="font-size-medium p-mt-2">
            <h4><b>Técnico: </b> {{ sheet.technician }}</h4>
          </div>
          <div v-if="sheet.sheet_number" class="font-size-medium">
            <b>Folha de obra nº: </b> {{ sheet.sheet_number }}
          </div>
          <div v-if="sheet.name" class="font-size-medium">
            <b>Nome da folha de instalação: </b> {{ sheet.name }}
          </div>
          <div v-if="sheet.observations" class="font-size-medium p-mt-2">
            <b>Observações à instalação: </b> {{ sheet.observations }}
          </div>

          <br />
          <div v-if="sheet.installation_name" class="font-size-medium p-mt-2">
            <b>Nome da instalação: </b> {{ sheet.installation_name }}
          </div>
          <div
            v-if="sheet.installation_description"
            class="font-size-medium p-mt-2"
          >
            <b>Descrição: </b> {{ sheet.installation_description }}
          </div>

          <div v-if="sheet.pat_name" class="font-size-medium p-mt-2">
            <b>Nome do Pat: </b> {{ sheet.pat_name }}
          </div>

          <div v-if="sheet.pat_resume" class="font-size-medium p-mt-2">
            <b>Resumo do Pat: </b> {{ sheet.pat_resume }}
          </div>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <form
            :name="sheet.reference"
            class="p-col-12 p-md-12"
            @submit.prevent="sheetEditSave(sheet)"
          >
            <div class="p-field p-col-12 p-md-6 p-mt-2">
              <span class="p-float-label">
                <Dropdown
                  :name="'installations_status' + sheet.reference"
                  v-model="sheet.dossier_status"
                  :options="statusList"
                  optionValue="value"
                  optionLabel="label"
                  v-bind:class="['form-control']"
                  :showClear="true"
                  :disabled="
                    sheet.dossier_initial_status === 'Concluído' ||
                      sheet.dossier_closed
                  "
                  @input="sheet.dossier_observations = ''"
                >
                </Dropdown>
                <label for="inputtext">Estado da Instalação</label>
              </span>
            </div>

            <div class="p-field p-col-12 p-md-6" style="margin-top: 25px">
              <span class="p-float-label">
                <Textarea
                  :name="'dossier_observations' + sheet.reference"
                  maxlength="67"
                  :disabled="
                    sheet.dossier_initial_status === 'Concluído' ||
                      sheet.dossier_closed
                  "
                  :autoResize="true"
                  v-model="sheet.dossier_observations"
                  v-bind:class="[
                    {
                      'p-invalid': errors.has(
                        'dossier_observations' + sheet.reference
                      )
                    },
                    'p-inputtextarea',
                    'p-inputtext',
                    'p-component'
                  ]"
                  rows="3"
                  v-validate="
                    sheet.dossier_status === 'pendente' ? 'required' : ''
                  "
                />
                <label for="inputtext">Observações</label>
              </span>
              <small
                v-if="errors.has('dossier_observations' + sheet.reference)"
                class="p-error"
                role="alert"
              >
                Observações é obrigatório
              </small>
            </div>
            <div
              class="p-col-12 p-md-6 p-fluid p-formgrid p-grid"
              v-if="
                sheet.dossier_initial_status !== 'Concluído' &&
                  sheet.dossier_closed == false
              "
            >
              <div class="p-col-12 p-md-6">
                <Button
                  label="Gravar"
                  icon="pi pi-check"
                  class="p-button-success"
                  @click="sheetEditSave(sheet)"
                />
              </div>
              <div class="p-col-12 p-md-6">
                <Button
                  label="Cancelar"
                  icon="pi pi-times"
                  class="p-button-danger"
                  @click="sheetEditCancel(sheet)"
                />
              </div>
            </div>
            <div v-if="sheet.dossier_closed" class="p-error" role="alert">
              <b>
                A folha de instalção encontra-se fechada e não permite edição
              </b>
            </div>
          </form>
        </div>
      </div>

      <h3>
        Material a Instalar
        <Button
          v-if="sheet.dossier_initial_status != 'Concluído' && !sheet.dossier_closed"
          label="Adicionar"
          icon="pi pi-plus"
          class="p-button-success p-button-outlined"
          @click="showFormNewItem(sheet.reference)"
        />
      </h3>
      <DataTable
        v-if="!loading"
        :value="sheet.items"
        :loading="loading"
        :paginator="false"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        :editMode="
          sheet.dossier_initial_status === 'Concluído' || sheet.dossier_closed
            ? ''
            : 'cell'
        "
        @cell-edit-init="onCellEdit"
        @cell-edit-complete="onCellEditSave"
        @cell-edit-cancel="onCellEditSave"
      >
        <template #empty> Nada a mostrar. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column
          field="reference"
          header="Referência"
          :headerStyle="{ width: '200px' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.reference }}
          </template>
        </Column>
        <Column field="designation" header="Descrição">
          <template #body="slotProps">
            {{ slotProps.data.designation }}
          </template>
        </Column>
        <Column
          field="quantity"
          header="Qtd a Instalar"
          :headerStyle="{ width: '100px' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.quantity }}
          </template>
        </Column>
        <Column
          field="installed_quantity"
          header="Qtd Instalada"
          :headerStyle="{ width: '100px' }"
          v-if="
            sheet.dossier_initial_status !== 'Concluído' &&
              sheet.dossier_closed == false
          "
        >
          <template #body="slotProps">
            <InputNumber
              v-model="slotProps.data.installed_quantity"
              mode="decimal"
              :maxFractionDigits="3"
              inputStyle="width: 90px; color:#000000; pointer-events:none;"
              disabled
            />
          </template>
          <template #editor="slotProps">
            <InputNumber
              v-model="slotProps.data[slotProps.column.field]"
              mode="decimal"
              :maxFractionDigits="3"
              inputStyle="width: 90px;"
              autofocus
            />
          </template>
        </Column>
        <Column
          field="installed_quantity"
          header="Qtd Instalada"
          :headerStyle="{ width: '100px' }"
          v-else
        >
          <template #body="slotProps">
            {{ slotProps.data.installed_quantity }}
          </template>
        </Column>
        <Column
          field="observations"
          header="Observações"
          v-if="
            sheet.dossier_initial_status !== 'Concluído' &&
              sheet.dossier_closed == false
          "
        >
          <template #body="slotProps">
            <InputText
              type="text"
              style="width: 100%; color:#0000000; pointer-events:none;"
              v-model="slotProps.data.observations"
              disabled
            />
          </template>
          <template #editor="slotProps">
            <InputText
              type="text"
              style="width: 100%"
              v-model="slotProps.data[slotProps.column.field]"
              autofocus
            />
          </template>
        </Column>
        <Column field="observations" header="Observações" v-else>
          <template #body="slotProps">
            {{ slotProps.data.observations }}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import taskSerervice from "../services/task.service";
import installationsSerervice from "../services/installations.service";
import dossierLinesService from "../services/dossierLines.service";
import TaskInstallationSheetForm from "./TaskInstallationSheetForm";

export default {
  name: "TaskInstallationSheet",
  components: {
    TaskInstallationSheetForm: TaskInstallationSheetForm
  },
  data() {
    return {
      loading: true,
      formNewItem: false,
      installationSheets: [],
      filters: {},
      editingRows: [],
      beforeEdit: null,
      sheetReference: null,
      valueOnInit: false,
      statusList: [
        { value: "pendente", label: "Pendente" },
        { value: "Concluído", label: "Concluído" }
      ]
    };
  },
  async created() {
    this.loading = true;
    await this.getInstallationSheetInfo().then(() => {
      this.installationSheets.forEach(element => {
        element["dossier_initial_status"] = element.dossier_status;
        element["dossier_initial_observations"] = element.dossier_observations;
      });
    });
    this.loading = false;
  },
  methods: {
    getInstallationSheetInfo() {
      if (
        this.$route.params.idInstallations != undefined &&
        this.$route.params.idInstallations == 1
      ) {
        return installationsSerervice
          .getInstallationSheets(this.$route.params.taskId)
          .then(response => {
            return (this.installationSheets = response);
          });
      }
      return taskSerervice
        .getTasksInstallationSheet(this.$route.params.taskId)
        .then(response => {
          return (this.installationSheets = response);
        });
    },
    onCellEdit(event) {
      if (event.field === "installed_quantity") {
        return (this.beforeEdit = event.data.installed_quantity);
      }
      if (event.field === "observations") {
        return (this.beforeEdit = event.data.observations);
      }
    },
    onCellEditCancel() {
      if (this.beforeEdit != undefined) {
        return (this.beforeEdit = null);
      }
      return;
    },
    async onCellEditSave(event) {
      if (event.field === "installed_quantity") {
        if (
          this.beforeEdit == event.newData.installed_quantity ||
          event.newData.installed_quantity == null ||
          event.newData.installed_quantity == undefined
        ) {
          return (this.beforeEdit = null);
        }

        return await this.updateLineQuantity(event);
      }

      if (event.field === "observations") {
        if (
          this.beforeEdit == event.newData.observations ||
          event.newData.observations == null ||
          event.newData.observations == undefined
        ) {
          return (this.beforeEdit = null);
        }

        return await this.updateLineObservations(event);
      }
    },
    updateLineQuantity(info) {
      let { data, newValue, field } = info;
      data[field] = newValue;
      let bodyParms = {
        id: info.newData.id,
        quantity: parseFloat(info.newData.installed_quantity)
      };
      return dossierLinesService
        .setInstalledQuantity(bodyParms)
        .then(response => {
          if (!response) {
            this.onCellEditCancel(info);
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao gravar",
              detail: "Ocorreu um erro ao gravar a quantidade instalada",
              life: 3000
            });
          }
          return this.$toast.add({
            severity: "success",
            summary: "Atualizado com sucesso",
            detail: "A quantidade instalada foi atualizada com sucesso",
            life: 3000
          });
        });
    },
    updateLineObservations(info) {
      let { data, newValue, field } = info;
      data[field] = newValue;
      let bodyParms = {
        id: info.newData.id,
        observations: info.newData.observations
      };
      return dossierLinesService
        .setLineObservations(bodyParms)
        .then(response => {
          if (!response) {
            this.onCellEditCancel(info);
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao gravar",
              detail: "Ocorreu um erro ao gravar a observação",
              life: 3000
            });
          }
          return this.$toast.add({
            severity: "success",
            summary: "Atualizado com sucesso",
            detail: "A observação foi atualizada com sucesso",
            life: 3000
          });
        });
    },
    showFormNewItem(sheetReference) {
      this.sheetReference = sheetReference;
      this.formNewItem = true;
    },
    submitNewItem(item) {
      const areYou = sheetAux => {
        return sheetAux.reference == this.sheetReference;
      };
      if (!item) {
        return this.$toast.add({
          severity: "error",
          summary: "Erro ao criar",
          detail: "Ocorreu um erro ao criar o item",
          life: 3000
        });
      }

      this.installationSheets[this.installationSheets.findIndex(areYou)][
        "items"
      ].push(item);
      this.canceledNewItem();
      return this.$toast.add({
        severity: "success",
        summary: "Criado com sucesso",
        detail: "O item fopi criado com sucesso",
        life: 3000
      });
    },
    canceledNewItem() {
      this.sheetReference = null;
      this.formNewItem = false;
    },
    sheetEditCancel(sheet) {
      sheet.dossier_observations = sheet.dossier_initial_observations;
      sheet.dossier_status = sheet.dossier_initial_status;
    },
    sheetEditSave(sheet) {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          return;
        }
        let bodyParams = {
          internalDossierid: sheet.reference,
          status: sheet.dossier_status,
          observation: sheet.dossier_observations
        };

        dossierLinesService.updateDossierStatus(bodyParams).then(response => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao gravar",
              detail: "Ocorreu um erro ao gravar a Folha de Instalação",
              life: 3000
            });
          }

          if (response.dossier_status == "Concluído") {
            return window.location.reload();
          }
          sheet.dossier_initial_observations = response.dossier_observations;
          sheet.dossier_initial_status = response.dossier_status;
          return this.$toast.add({
            severity: "success",
            summary: "Atualizado com sucesso",
            detail: "A Folha de Instalação foi atualizada com sucesso",
            life: 3000
          });
        });
      });
    }
  }
};
</script>
<style scoped lang="scss">
.font-size-medium {
  font-size: medium;
}
</style>
